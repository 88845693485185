$button: white;
//nav
$beige: #FDC093;
$colorText: #1A0312;
@mixin navLinkSizeAndColor() {  
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 38.4px */
  letter-spacing: 1.2px;
  color: #12773D;
  text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25); 
}

@mixin title { 
  margin: 0 auto;
  font-style: normal;
  font-weight: 750;
  font-size: 66px;
  line-height: 120%;
  letter-spacing: 3.6px;
  color: #000;
  text-align: center;
}

