@import "../../vendor/variables";

.contacts{
    max-width: 1640px;
    margin: 100px auto 0 auto;
    padding-top: 150px;

    &__title{
        @include title()
    }

    &__wrapper {
        margin-top: 50px;
        display: flex;
        justify-content: space-between;
        gap: 12px;
     }   
    

    &__content{
        position: relative;
        z-index: 2;
        display: flex;
        flex-direction: column;
    }

    &__play{
        position: relative;        
        width: 720px;
        height: 480px;
        object-fit: cover;
        box-sizing: border-box;
        z-index: 2;
        border-radius: 15px;
        border: 4px solid #74BF48;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);
    }

    &__ellipse1{
        position: absolute;
        top: -100px;
        right: -100px;
        z-index: 1;
    }

    &__container{
        margin-top: 50px;
        display: flex;
        gap: 80px;
    }

    &__info{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 10px;
    }

    &__contacttext{
        margin: 0;
        text-decoration: none;
        color: #8F8F8F;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;
        letter-spacing: 1.2px;
    }

    &__link{
        &:nth-child(1){
            margin-top: 15px;
        }
    }

    &__sites{
        display: flex;
        gap: 60px;
    }

    &__site{
        cursor: pointer;
        max-width: 200px;
    }

    &__button{
        background-image: url(../../images/button.svg);
        background-repeat: no-repeat;
        background-size: contain;
        background-color: inherit;
        background-position: center;
        cursor: pointer;
        padding: 0;
        width: 150px;
        height: 150px;
        border: none;
        position: absolute;
        top: 23%;
        right: 41%;
        z-index: 3;
    
        &_invisible{
        background-image: none;
        background-color: inherit;
        padding: 0;
        width:58px;
        height: 47px;
        border: none;
        position: absolute;
        top: 42%;
        right: 50%;
        }
    }

    &__girl{
        margin-top: 80px;
        height: 501px;
    }

    &__boy{
        margin-top: 80px;
        height: 501px;
    }
}

@media screen and (max-width: 1640px){
    .contacts{
        max-width: 1360px;
        padding-top: 75px;

        &__title{
            font-size: 60px;
        }

        &__wrapper{
            justify-content: center;
        }

        &__play{
            width: 750px;
            object-fit: cover;
            box-sizing: border-box;
        }

        &__button{
            top: 25%;
            width: 120px;
            height: 120px;

            &_invisible{
                top: 20%;
            }
        }

        &__container{
            margin-top: 60px;
            gap: 100px;
        }

        &__sites{
            gap: 60px;
        }

        &__girl{
           height: 350px;
           margin-top: 100px;
        }

        &__boy{
            height: 350px;
            margin-top: 100px;
        }

        &__ellipse1{
            top: -70px;
            right: -70px;
            width: 150px;
        }
    }    
}

@media screen and (max-width: 1440px){
    .contacts{
        max-width: 1160px;

        &__title{
            font-size: 54px;
        }

        &__play{
            width: 750px;
        }

        &__girl{
            width: 200px;
        }

        &__boy{
            width: 200px;
        }

        &__ellipse1{
            right: -40px;
        }
    }    
}

@media screen and (max-width: 1160px){
    .contacts{
        max-width: 880px;
        position: relative;

        &__wrapper{
            margin-top: 501px;
            flex-direction: column;
        }

        &__container{
            margin: 60px auto 0 auto;
            flex-direction: column-reverse;
            gap: 50px;
        }

        &__content{
            flex-wrap: wrap;
            align-content: center;
        }

        &__girl{
            position: absolute;
            height: 421px;
            width: auto;
            left: 100px;
            top: 250px;
            margin-top: 0;
        }

        &__boy{
            position: absolute;
            height: 421px;
            width: auto;
            right: 100px;
            top: 250px;
            margin-top: 0;
        }

        &__info{
            align-items: center;
        }

        &__ellipse1{
            top: 320px;
            right: 36%;
        }

        &__button{
            width: 100px;
            height: 100px;
            top: 20%;
            right: 43%;
        
            &_invisible{
                width: 100px;
                height: 100px;
            }
        }
    }    
}

@media screen and (max-width: 960px){
    .contacts{
        max-width: 648px;

        &__title{
            font-size: 50px;
        }

        &__wrapper{
            margin-top: 400px;
            flex-direction: column;
        }

        &__play{
            width: 550px;
        }

        &__ellipse1{
            top: 200px;
        }

        &__girl{
            height: 321px;
            top: 200px;
        }

        &__boy{
            height: 321px;
            top: 200px;
        }

        &__button{
            width: 100px;
            height: 100px;
        
            &_invisible{
                width: 100px;
                height: 100px;
            }
        }
    }
}

@media screen and (max-width: 648px){
    .contacts{
        max-width: 586px;
        margin: 0 auto;

        &__wrapper{
            margin-top: 345px;
        }

        &__title{
            font-size: 40px;
            line-height: 120%; /* 48px */
            letter-spacing: 2px;
        }

        &__girl{
            height: 260.5px;
           
            top: 200px;
        }

        &__boy{
            height: 260.5px;
            
            top: 200px;
        }

        &__play{
            margin: 0 auto;
            width: 320px;
            height: 340px;
        }

        &__ellipse1{
            display: none;
        }

        &__container{
            margin: 30px auto 0 auto;
            gap: 20px;
        }

        &__sites{
            flex-direction: column;
            justify-content: center;
            flex-wrap: wrap;
            align-content: center;
            gap: 20px;
        }

        &__link{
            max-width: 130px;
            &:nth-child(2){
                max-width: 80px;
                margin: 0 auto;
            }
        }

        &__site{
            max-width: 100%;
        }

        &__button{
            width: 50px;
            height: 50px;
            top: 16%;
            right: 45%;
        
            &_invisible{
                width: 50px;
                height: 50px;
            }
        }

        &__contacttext{
            font-size: 18px;
        }
    }
}

@media screen and (max-width: 586px){
    .contacts{
        max-width: 328px;

        &__girl{
            left: 0;
        }

        &__boy{
           right: 0;
        }

        &__button{
            right: 40%;
        }
       
    }
}