.video {
    max-width: 1640px;
    margin: 200px auto 0 auto;

    &__tablet{
        display: none;
    }

    &__title{
        margin: 50px 0 50px 0;
        color: #12773D;
        font-size: 36px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; 
        letter-spacing: 2px;

        &:last-child{
            text-align: end;
        }
    }

    &__content{
        display: block;
        position: relative;
        margin: 0 auto;
        max-width: 1100px;
        height: 631px;
        border-radius: 15px;
    }

    &__play{
       max-width: 100%;
       border-radius: 15px;
       box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
       position: relative;
       z-index: 2;
    }

    &__ellipse1{
        width: 150px;
        position: absolute;
        z-index: 1;
        right: -70px;
        top: 126px;
    }

    &__ellipse2 {
        width: 150px;
        position: absolute;
        z-index: 1;
        left: -70px;
        bottom: 20%;
       }

       &__button{
        background-image: url(../../images/button.svg);
        background-repeat: no-repeat;
        background-size: contain;
        background-color: inherit;
        background-position: center;
        cursor: pointer;
        padding: 0;
        width: 150px;
        height: 150px;
        border: none;
        position: absolute;
        top: 35%;
        right: 41%;
        z-index: 3;
    
        &_invisible{
        background-image: none;
        background-color: inherit;
        padding: 0;
        width:58px;
        height: 47px;
        border: none;
        position: absolute;
        top: 42%;
        right: 50%;
        }
    }
}

@media screen and (max-width: 1640px){
    .video{
        max-width: 1360px;
    }    
}

@media screen and (max-width: 1470px){
    .video{
        max-width: 1160px;

        &__content{
            max-width: 700px;
            height: 100%;
        }

        &__title{
            font-size: 32px;
            margin: 10px 0 10px 0;
        }

        &__ellipse1{
            top: 76px;
        }

        &__ellipse2{
            bottom: 15%;
        }

        &__button{
            width: 120px;
            height: 120px;
            right: 40%;
        }
    }    
}

@media screen and (max-width: 1160px){
    .video{
        max-width: 880px;

        &__ellipse1{
            position: absolute;
            z-index: 1;
            right: -90px;
            top: 10%;
        }
    
        &__ellipse2 {
            position: absolute;
            z-index: 1;
            left: -90px;
            bottom: 20%;
           }
    }
}

@media screen and (max-width: 1024px){
    .video{
        &__content{
            margin-top: 50px;
            width: 641px;
            height: 360px;
        }

        &__ellipse2{
            display: none;
        }

        &__tablet{
            display: flex;
            justify-content: center;
            gap: 42px;
        }

        &__podlozhka{
            padding: 20px;
            border-radius: 15px;
            background: #74BF48;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);
        }

        &__title{
            font-size: 24px;
            line-height: 160%; 
            letter-spacing: 1.2px;
            text-align: center;

            &:last-child{
                margin-top: 120px;
                text-align: center;
            }
        }

        &__ellipse1{
            right: 220px;
            top: 70%;
        }

        &__button{
            width: 100px;
            height: 100px;
        
            &_invisible{
                width: 100px;
                height: 100px;
            }
        }
    }
}

@media screen and (max-width: 960px){
    .video{
        max-width: 648px;

        &__tablet{
            height: 400px;
        }

        &__oblozhka{
            height: 100%;
        }

        &__dev{
            height: 350px;
        }

        &__content{
            width: 540px;
        }

        &__ellipse1{
            right: 200px;
            top: 90%;
        }
    } 
}

@media screen and (max-width: 648px){
    .video{
        max-width: 586px;
        margin: 40px auto 0 auto;

        &__podlozhka{
            padding: 11px;
            width: 280px;
            height: 400px;
        }

        &__content{
            width: 320px;
            height: 180px;
        }

        &__dev{
            display: none;
        }

        &__oblozhka{
            width: 100%;
            height: 100%;
        }

        &__ellipse1{
            right: 110px;
            top: 110%;
            width: 100px;
        }

        &__button{
            width: 50px;
            height: 50px;
        
            &_invisible{
                width: 50px;
                height: 50px;
            }
        }
    }
}
