.formOnly{
    margin: auto 32px 58px 32px;
    display: flex;
    flex-direction: column;
    width: max-content;
    margin: 0 auto;

    &__title{
        margin: 14px auto;
        text-align: center;
        color: #FFF;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 160%;
        letter-spacing: 1.2px;
    }

    &__input{
        padding-left: 21px;
        padding-right: 21px;
        width: 380px;
        height: 40px;
        margin-bottom: 16px;
        border: none;
        border-radius: 15px;
        background: #FFF;
        color: #000;
        box-shadow: 2px 4px 4px 0px rgba(0, 0, 0, 0.09);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;
        letter-spacing: 0.8px;
    }

    &__button {
        margin: 15px auto 0 auto;
        width: 345px;
        height: 44px;
        border-radius: 15px;
        background: #FFF;
        border: none;
        box-shadow: 2px 4px 4px 0px rgba(0, 0, 0, 0.09);
        color: #12773D;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 160%; /* 38.4px */
        letter-spacing: 1.2px;
    }

    &__container_check {
        margin: auto;
        display: flex;
        align-items: center;
        gap: 10px;
    }

    &__checkbox{
        width: 24px;
        height: 24px;
        border: 3px solid #FFF;
        cursor: pointer;
    }

    &__link{
        margin: 0;
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; 
        letter-spacing: 0.8px;
    }

    &__link_accent {
        text-decoration: underline;
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; 
        letter-spacing: 0.8px;
    }
}

@media screen and (max-width: 1440px){
    .formOnly{
        margin: auto 32px 30px 32px;
    }
}

@media screen and (max-width: 648px){
    .formOnly{
        margin: auto 12px 12px 7px;

        &__input{
            height: 50px;
            width: auto;
            &:nth-child(4){
                margin-bottom: 0;
            }
        }

        &__button{
            font-size: 18px;
            width: 250px;
            height: 50px;
        }

        &__container_check{
            margin-top: 10px;
        }

        &__link{
            text-align: center;
            width: 250px;
        }
    }
}