@import "../../vendor/variables";

.about{
    max-width: 1640px;
    margin: 0 auto;
    padding-top: 150px;

    &__title{
        @include title()
    }

    &__content{
        display: flex;
        justify-content: center;
    }

    &__girl{
        padding-top: 6%;
        height: 501px;
    }

    &__boy{
        padding-top: 12%;
        padding-left: 20px;
        height: 501px;
    }

   &__textZone {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    position: relative;
   }

   &__firstP{
    position: relative;
    z-index: 2;
    margin: 0 0 50px 0;
    max-width: 930px;
    max-height: 255px;
    padding: 20px 22px 24px 20px;
    border-radius: 15px;
    border: 4px solid #74BF48;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);
    color: #8F8F8F;
    font-size: 24px;
    line-height: 160%;
    letter-spacing: 1.1px;
   }

   &__krugLittle{
    position: absolute;
    top: -23px;
    left: -23px;
   }

   &__secondP{
    position: relative;
    z-index: 2;
    margin: 0;
    margin-left: 100px;
    max-width: 894px;
    max-height: 378px;
    padding: 20px 21px 30px 20px;
    border-radius: 15px;
    border: 4px solid #12773D;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);
    color: #8F8F8F;
    font-size: 24px;
    line-height: 160%;
    letter-spacing: 1.1px;
   }

   &__ellipse1{
    width: 150px;
    position: absolute;
    z-index: 1;
    right: -5px;
    top: 7%;
   }

   &__ellipse2 {
    width: 150px;
    position: absolute;
    z-index: 1;
    left: 20px;
    top: 70%;
   }
}

@media screen and (max-width: 1640px){
    .about{
        max-width: 1360px;
        padding-top: 75px;

        &__title{
            font-size: 60px;
        }

        &__textZone{
            margin-top: 20px;
        }

        &__firstP{
            max-height: 100%;
            margin: 0 0 30px 0;
            font-size: 20px;
            letter-spacing: 0;
        }

        &__secondP{
            max-height: 100%;
            font-size: 20px;
            letter-spacing: 0;
            padding: 20px 21px 20px 20px;
        }

        &__ellipse1{
            position: absolute;
            z-index: 1;
            right: -80px;
            top: 5%;
        }

        &__ellipse2{
            top: 60%;
        }

        &__boy{
            height: 401px;
            padding-top:20%;
        }

        &__girl{
            height: 401px;
        }
    }    
}

@media screen and (max-width: 1440px){
    .about{
        max-width: 1160px;

        &__title{
            font-size: 54px;
        }

        &__girl{
            padding-top: 8%;
            padding-right: 20px;
        }
    
        &__boy{
            padding-top: 30%;
        }

        &__firstP{
            font-size: 20px;
            padding: 10px 12px 12px 20px;
        }

        &__secondP{
            font-size: 20px;
            margin-left: 0;
            padding: 10px 12px 12px 20px;
        }

        &__ellipse2{
            left: -100px;
        }
    }
}

@media screen and (max-width: 1160px){
    .about{
        max-width: 880px;

        &__content{
            flex-direction: column;
            position: relative;
            margin-top: 521px;
        }

        &__girl{
            position: absolute;
            padding-top: 0;
            left: 20px;
            top: -500px;
        }

        &__boy{
            padding-top: 0;
            position: absolute;
            right: 20px;
            top: -500px;
        }

        &__textZone{
            flex-wrap: wrap;
            align-items: center;
        }

        &__firstP{
            max-width: 644px;
        }

        &__secondP{
            max-width: 644px;
        }

        &__ellipse1{
            right: 0;
        }

        &__ellipse2{
            left: 0;
        }
    }    
}

@media screen and (max-width: 960px){
    .about{
        max-width: 648px;

        &__title{
            font-size: 50px;
        }

        &__ellipse1,
        &__ellipse2
        {
          display: none;
        }

        &__content{
            margin-top: 321px;
        }

        &__girl{
            height: 321px;
            top: -310px;
            left: 80px;
        }

        &__boy{
            height: 321px;
            top: -310px;
            right: 80px;
        }

        &__firstP, 
        &__secondP{
            font-size: 16px;
        }
        
    } 
}

@media screen and (max-width: 648px){
    .about{
        max-width: 586px;

        &__title{
            font-size: 40px;
            letter-spacing: 2px;
        }

        &__firstP{
            max-width: 324px;
            font-size: 16px;
            line-height: 160%; 
            letter-spacing: 0.8px;
        }

        &__secondP{
            max-width: 324px;
            font-size: 16px;
            line-height: 160%; 
            letter-spacing: 0.8px;
        }

        &__ellipse1{
            right: 3%;
            top: 15%
        }

        &__ellipse2{
            left: 3%;
            top: 68%
        }

        &__content{
            margin-top: 280px;
        }

        &__girl{
            height: 260px;
            top: -260px;
        }

        &__boy{
            height: 260px;
            top: -260px;
        }
    }
}

@media screen and (max-width: 586px){
    .about{
        max-width: 328px;

        &__ellipse1{
            display: none;
        }

        &__ellipse2{
            display: none;
        }

        &__girl{
            left: 10px;
        }

        &__boy{
            right: 10px;
        }
    } 
}