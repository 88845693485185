@import "../../vendor/variables";
.layout {
  max-width: 1640px;
  padding: 31px 0 31px ;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;

  &__logotip{
    width: 64px;
    height: 54px;       
}

}
.header {
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    top: 0;
    position: fixed;
    width: 100%;
    z-index: 100;

  &__contact {
    display: flex;
    list-style: none;

   &__button{
    border-radius: 15px;
    border: 2px solid #74BF48;
    background: #FFF;
   }
  }

  &__nav {
    display: flex;
    gap: 130px;
    font-weight: normal;

    &Item {
      text-decoration: none;
      @include navLinkSizeAndColor();

      &_active {
        @include navLinkSizeAndColor();
        font-weight: bold;
      }
    }
  }

  &__menuImage{
    display: none;
  }
}

.club{
  &__button{
    cursor: pointer;
    padding: 9px 21px;
    border-radius: 15px;
    border: 2px solid #74BF48;
    background: #FFF;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    color: #12773D;
    font-weight: bold;
    font-size: 24px;
    line-height: 160%;
    letter-spacing: 1.2px;
    &:hover{
      color: #74BF48;
    }
    &:active{
      color: #FFF;
      border-radius: 15px;
      border: 2px solid #FFF;
      background: #12773D;
    }
    
   }

   &__phone{
    display: none;
   }
}

@media screen and (max-width: 1640px){
  .layout{
      padding: 10px 0 10px;
      justify-content: space-evenly;
  }    

  .header{
    &__nav {
      gap: 80px;

      &Item{
        font-size: 22px;
      }
    }
  }

  .club{
    &__button{
      font-size: 22px;
      line-height: 120%;
      letter-spacing: 1.2px;
    }
  }
}

@media screen and (max-width: 1160px){
  .header{
    &__nav {
      gap: 30px;

      &Item{
        font-size: 20px;
      }
    }
  }

  .club{
    &__button{
      font-size: 20px;
      line-height: 120%;
      letter-spacing: 1.2px;
      padding: 9px 10px;
      border-radius: 10px;
    }
  }
}

@media screen and (max-width: 980px) {
  .layout {
    max-width: 560px;
    padding: 0;
    justify-content: space-between;

  }
  .header {
    height: 58px;
    width: 100%;

    &__logo{ 
      margin-left: 73%;
    }


    &__nav {
      align-items: center;
      gap: 20px;
      display: none;
      z-index: 500;
      flex-direction: column;
      position: fixed;
      width: 100%;
      height: 100%;
      background: white;
      top: 0;
      right: 0;
      text-align: center;
      padding: 100px 0;
      transition: .7s;
      background-repeat: no-repeat;
    
      &Item {
        color: #12773D;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.10);
        font-family: "ComicSansMS", "Comic Sans", "Chalkboard", "ChalkboardSE-Regular", sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 160%; /* 32px */
        letter-spacing: 1px;
        text-transform: uppercase;

        &_active {
          color: white;
        }
      }
      &_open {
        display: flex;
        justify-content: flex-start;
      }
    }


    &__menuImage{
      margin-top: 50px;
      display: block;
      background-color:  transparent;
    }


    &__close {
      cursor: pointer;
      background-repeat: no-repeat;
      border: none;
      outline: none;
      background-color: inherit;
      z-index: 1000;
      display: flex;
      left: 0;
      top: 0;
      width: 44px;
      height: 44px;
      background-image: url(../../images//menu.svg);
      &__open {
        background-size: contain;
        background-image: url(../../images/closee.svg);
        background-color: #12773D;
        border-radius: 30px;
      }
    } 

    

    .club{
      &__button{
        display: none;
      }

      &__phone{
        display: block;
      }
    }
  }
}

@media screen and (max-width: 768px){
  .layout {
    padding: 0 40px;
  }

  .header {
    &__modal_close{
      top: -10px;
      left: -50%;
    }
  }
}

@media screen and (max-width: 425px){
  .header {
    &__modal_close{
      top: -10px;
      left: 1%;
    }
    
  }
}