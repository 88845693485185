.footer {
    max-width: 1640px;
    margin: 70px auto 25px auto;

    &__text{
        margin: 0 auto;
        text-align: center;
        color: #74BF48;
        text-shadow: 4px 6px 15px rgba(0, 0, 0, 0.25);       
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; 
        letter-spacing: 0.8px;   
    }

    &__span{
        color: #12773D;;
    }
}