.error404{
    height: 100vh;
    max-width: 1640px;
    margin: auto;
    display: flex;
    flex-direction: column;

    &__title{
        margin: 133px auto 50px auto;
        color: #12773D;
        font-size: 128px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%; 
        letter-spacing: 6.4px;
    }

    &__container{
        margin: 0 auto;
        display: flex;
        flex-direction: column;
    }

    &__subtitle{
        margin: 0;
        color: #000;
        font-size: 72px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%; 
        letter-spacing: 3.6px;
    }

    &__wrap{
        margin-top: 50px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        align-items: center;
        width: 230px;
        height: 56px;
        border-radius: 15px;
        border: 2px solid #12773D;
        background: #12773D;
    }

    &__button{
        text-decoration: none;
        color: #FFF;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 160%;
        letter-spacing: 1.2px;
    }
}

@media screen and (max-width: 1640px){
    .error404{
        max-width: 1360px;
    }    
}

@media screen and (max-width: 1440px){
    .error404{
        max-width: 1160px;
    }    
}

@media screen and (max-width: 1160px){
    .error404{
        max-width: 880px;
    }    
}

@media screen and (max-width: 960px){
    .error404{
        max-width: 648px;

        &__title{
            margin: 120px auto 50px auto;
        }

        &__subtitle{
            margin: 0;
            font-size: 40px;
            line-height: 120%; 
            letter-spacing: 2px;
        }
    } 
}

@media screen and (max-width: 648px){
    .error404{
        max-width: 586px;
    }    
}

@media screen and (max-width: 586px){
    .error404{
        max-width: 328px;

        &__container{
            align-items: center;
        }

        &__subtitle{
            text-align: center;
        }
    }    

}