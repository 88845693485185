@import "../../vendor/variables";

.faq{
    max-width: 1640px;
    margin: 200px auto 0 auto;

    &__title{
        @include title()
    }

    &__content{
        display: flex;
        flex-direction: column;
        margin-top: 45px;
    }

    &__paragraph{
        display: flex;
        justify-content: space-between;
        border-top:#12773D 1.5px solid;
    }

    &__question{
        color: #12773D;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 160%;
        letter-spacing: 1.2px;
    }

    &__answer{
        width: 1140px;
        color: #8F8F8F;
        font-size: 22px;
        text-align: justify;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 38.4px */
        letter-spacing: 1px;
    }
}

@media screen and (max-width: 1640px){
    .faq{
        max-width: 1360px;
        margin: 100px auto 0 auto;
        padding-top: 75px;

        &__title{
            font-size: 60px;
        }

        &__question{
            width: 190px;
        }

        &__answer{
            font-size: 22px;
            letter-spacing: 0;
        }
    }    
}

@media screen and (max-width: 1440px){
    .faq{
        max-width: 1160px;

        &__title{
            font-size: 52px;
        }

        &__question{
            font-size: 20px;
        }

        &__answer{
            font-size: 18px;
        }

        &__content{
            margin-top: 30px;
        }
    }    
}

@media screen and (max-width: 1160px){
    .faq{
        max-width: 880px;

        &__paragraph{
            flex-direction: column;
            align-items: center;
        }

        &__question{
            width: 640px;
            text-align: center;
        }

        &__answer {
            width: 640px;
        }
    }    
}

@media screen and (max-width: 960px){
    .faq{
        max-width: 648px;
    }
}

@media screen and (max-width: 648px){
    .faq{
        max-width: 586px;
        margin: 100px auto 0 auto;

        &__title{
            font-size: 40px;
            line-height: 120%; 
            letter-spacing: 2px;
        }

        &__content{
            margin-top: 20px;
        }

        &__question{
            width: 100%;
            margin: 0;
        }

        &__answer{
            width: 350px;
            font-size: 16px;
            line-height: 160%; 
            letter-spacing: 0.8px;
        }
    }
}

@media screen and (max-width: 586px){
    .faq{
        max-width: 328px;

        &__answer{
            width: 318px;
        }
    }
}