@import "../../vendor/normalize";
//@import "../../vendor/font/font.scss";
body {
  background: #FFF;
}

.page {
  text-decoration: none;
  margin: 0 auto;
  padding: 0;
  width: 100%;
  min-width: 320px;
  color: #1A0312;
  font-family: "ComicSansMS", 'Comic Sans MS', "ComicSans", "Chalkboard", "ChalkboardSE-Regular", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
}