@import "../../vendor/variables";

.authors{
    max-width: 1640px;
    margin: 100px auto 0 auto;
    padding-top: 150px;
    
    &__title1{
        @include title();
    }

    &__content{
        margin-top: 50px;
        display: flex;
        justify-content: space-between;
    }

    &__girl{
        width: 268px;
        height: 521px;
    }

    &__person{
        width: 571px;
        height: 521px;
        border-radius: 15px;
        border: 4px solid #12773D;
        background: #FFF;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    }

    &__title{
        display: flex;
        gap: 30px;
        margin-top: 25px;
        margin-left: 20px;
    }

    &__name{
        max-width: 360px;
        color: #000;
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%; 
        letter-spacing: 2px;
    }

    &__text{
        padding-left: 20px;
        color: #8F8F8F;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; 
        letter-spacing: 1px;
    }

    &__speech{
        max-width: 100%;
        padding: 10px 15px 10px 20px;
        margin: 60px auto 0 auto;
        border-radius: 15px;
        border: 2.5px solid #000;
        background: #74BF48;
        box-shadow: 4px 6px 15px 0px rgba(0, 0, 0, 0.25);
        color: #FFF;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; 
        letter-spacing: 1.2px;
    }
}

@media screen and (max-width: 1640px){
    .authors{
        max-width: 1360px;
        padding-top: 75px;

        &__title1{
            font-size: 60px;
        }

        &__content{
            margin-top: 30px;
        }

        &__person{
            height: 421px;
        }

        &__name{
            font-size: 36px;
            margin: 0;
        }

        &__text{
            letter-spacing: 0;
        }

        &__img{
            width: 100px;
            height: 100px;
        }

        &__girl{
            width: 168px;
            height: 350px;
            padding-top: 80px;
        }

        &__speech{
            margin: 40px auto 0 auto;
            letter-spacing: 0;
        }
    }    
}

@media screen and (max-width: 1440px){
    .authors{
        max-width: 1160px;

        &__title1{
            font-size: 54px;
        }

        &__title{
            margin-top: 15px;
            margin-left: 15px;
        }

        &__name{
            font-size: 28px;
            margin: 0;
        }

        &__person{
            height: auto;
        }

        &__img{
            width: 80px;
            height: 80px;
        }

        &__text, 
        &__speech{
           font-size: 20px;
        }

        &__girl{
            display: none;
        }

        &__content{
            gap: 30px;
        }
    }    
}

@media screen and (max-width: 1160px){
    .authors{
        max-width: 880px;

        &__title1{
            text-align: center;
        }

        &__content{
            flex-direction: column;
            gap: 50px;
        }

        &__person{
            border: 4px solid #74BF48;

            &:nth-child(3){
                margin-left: 15%;
             }
        }

        &__speech{
            margin: 50px auto 0 0;
            width: 640px;    
        }
    }  
}

@media screen and (max-width: 960px){
    .authors{
        max-width: 648px;
        margin: 100px auto 0 auto;

        &__title1{
           text-align: center;
           font-size: 50px;
        }

        &__title{
            align-items: center;
            gap: 15px;
        }

        &__name{
            font-size: 24px;
        }

        &__text{
            font-size: 18px;
            margin: 0 auto 20px;
        }

        &__speech{
            font-size: 18px;
            height: auto;
        }
    }
}

@media screen and (max-width: 648px){
    .authors{
        max-width: 586px;

        &__title1{
            font-size: 40px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%; 
            letter-spacing: 2px;
        }

        &__content{
            align-items: center;
        }

        &__person{
            width: 318px;
            height: auto;

            &:nth-child(3){
                margin-left: 0;
             }
        }

        &__title{
            gap: 10px;
            justify-content: center;
            align-items: center;
        }

        &__name{
            font-size: 24px;
            font-weight: 700;
            line-height: 160%; 
            letter-spacing: 1.2px;
        }

        &__img{
            width: 75px;
            height: 75px;
        }

        &__text{
            font-size: 16px;
            line-height: 160%;
            letter-spacing: 0.8px;
        }

        &__speech{
            margin: 0 auto;
            padding: 20px 10px 10px 10px;
            margin-top: 40px;
            width: 300px;
            font-size: 16px;
            line-height: 160%; 
            letter-spacing: 0.8px;
        }
}
}