@import "../../vendor/variables";

.reviews{
    max-width: 1640px;
    margin: 100px auto 0 auto;
    padding-top: 150px;
    
    &__title{
        @include title()
    }

    &__content{
        margin-top: 50px;
        display: flex;
        gap: 38px;
        position: relative;
        z-index: 2;
    }

    &__person{
        display: flex;
        align-items: center;
        min-height: 126px;
        gap: 30px;
        margin-top: 25px;
        margin-left: 20px;
    }

    &__ellipse2{
        position: absolute;
        top: 31%;
        left: 29%;
    }

    &__ellipse1{
        position: absolute;
        top: 31%;
        right: 29%;
    }

    &__ellipse3{
        display: none;
    }

    &__speech{
        margin: 30px 0 150px 0;
        text-align: end;
        color: #12773D;
        font-size: 38px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        letter-spacing: 2px;
    }

    &__wrapper{
        width: 521px;
        height: auto;
        border-radius: 15px;
        border: 4px solid #12773D;
        background: #FFF;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

        &:nth-child(5){
            border: 4px solid #74BF48;;
        }
    }

    &__box{
        display: flex;
        flex-direction: column;
    }

    &__name{
        margin: 0;
        color: #000;
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%; /* 48px */
        letter-spacing: 2px;
        &_span{
            margin: 0;
            font-size: 18px;
            font-weight: 400;
            line-height: 160%;
            letter-spacing: 0;
        }
    }

    &__text{
        padding: 20px;
        margin: 0;
        color: #8F8F8F;
        font-size: 18px;
        text-align: justify;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 32px */
        letter-spacing:0;
    }
}

@media screen and (max-width: 1640px){
    .reviews{
        max-width: 1360px;
        padding-top: 75px;

        &__title{
            font-size: 60px;
        }

        &__person{
            margin-top: 15px;
            margin-left: 10px;
        }

        &__name{
            font-size: 26px;
        }

        &__girl{
            width: 100px;
            height: 100px;
        }

        &__content{
            margin-top: 30px;
        }

        &__text{
            padding: 0 20px 20px;
        }

        &__speech{
            font-size: 34px;
        }
    }    
}

@media screen and (max-width: 1440px){
    .reviews{
        max-width: 1160px;

        &__title{
            font-size: 54px;
        }

        &__wrapper{
            height: auto;
        }

        &__person{
            margin-top: 10px;
        }

        &__girl{
            width: 80px;
            height: 80px;
        }

        &__text{
            font-size: 16px;
        }

        &__name{
            font-size: 22px;
        }

        &__speech{
            font-size: 32px;
            margin: 20px 0 150px 0;
        }
    }    
}

@media screen and (max-width: 1160px){
    .reviews{
        max-width: 880px;

        &__content{
            flex-direction: column;
            align-items: center;
        }

        &__wrapper{
            width: 571px;
            height: auto;
            margin-left: 0;

            &:nth-child(5){
                margin-left: 25%;
            }
        }

        &__name{
            font-size: 40px;
        }

        &__speech{
            text-align: start;
        }

        &__ellipse1{
            top: 80%;
            right: 13%;
        }

        &__ellipse2{
            top: 10%;
            left: 64%;
        }

        &__ellipse3{
            display: block;
            position: absolute;
            top: 44%;
            right: 25%;
        }
    }    
}

@media screen and (max-width: 960px){
    .reviews{
        margin: 100px auto 100px auto;
        max-width: 648px;

        &__title{
            font-size: 50px;
        }

        &__name{
            font-size: 30px;
        }
        
    }
}

@media screen and (max-width: 648px){
    .reviews{
        max-width: 586px;

        &__title{
            font-size: 40px;
            line-height: 120%; 
            letter-spacing: 2px;
        }

        &__wrapper{
            width: 318px;
            height: 100%;
            margin-left: 0;

            &:nth-child(5){
                margin-left: 0;
            }
        }

        &__name{
            margin: 0;
            font-size: 24px;
            line-height: 160%; 
            letter-spacing: 1.2px;
            width: 153px;
        }

        &__person{
            gap: 15px;
        }

        &__girl{
            width: 82px;
            height: 82px;
        }

        &__text{
            font-size: 16px;
            line-height: 160%;
            letter-spacing: 0.8px;
        }

        &__ellipse1{
            display: none;
        }

        &__ellipse2{
            display: none;
        }

        &__ellipse3{
            display: none;
        }

        &__speech{
            margin: 20px auto 20px auto;
            width: 316px;
            font-size: 24px;
            line-height: 160%; /* 38.4px */
            letter-spacing: 1.2px;
        }
    }
}