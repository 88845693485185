@import "../../vendor/variables";

.form{
    max-width: 1640px;
    margin: 200px auto 0 auto;
    padding-top: 150px;

    &__title{
        @include title()
    }

    &__content {
        position: relative;
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 45px;
    }

    &__emizavrik{
        width: 240px;
        right: 120px;
        top: -220px;
    
    }

    &__main_container{
        display: flex;
        justify-content: space-around;
    }

    &__square {
        z-index: 2;
        width: 480px;
        height: 330px;
        background: #74BF48;
        border-radius: 15px;
        border: 4px solid #12773D;
    }

    &__subtitle{
        margin: 0 auto 30px auto;
        color: #02092F;
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%; 
        letter-spacing: 2px;
    }

    &__text_container{
        display: flex;
        justify-content: space-between;
    }

    &__link_container, &__oblozhka_container{
        max-width: 620px;
    }

    &__mainText{
        color: #8F8F8F;
        align-self: center;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: 200%;
        letter-spacing: 1.4px;
        margin: 0;
    }
    

    &__text{
        color: #8F8F8F;
        margin: 0;
        max-width: 620px;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; 
        letter-spacing: 1.2px;
        padding: 10px;
        margin-bottom: 20px;
    }

    &__oblozhka{
        display: flex;
        flex-direction: column;
        height: 160px;
    }

    &__izdatel{
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;
    }

    &__marketplaces{
        height: 160px;
        max-width: 620px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__span{
        margin: 80px 0 0;
        color: #12773D;
        font-size: 36px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 64px */
        letter-spacing: 2px;

        &:last-child{
            margin-top: 20px;
            text-align: end;
        }
    }

    &__link{
        max-width: 100%;
        max-height: 80px;

        &:nth-child(0){
            width: 400px;
            
        }
    }

    &__obl{
        max-width: 100%;
        max-height: 160px;
        object-fit: contain;
    }

    &__hide{
        display: none;
    }
}

@media screen and (max-width: 1640px){
    .form{
        max-width: 1360px;
        margin: 100px auto 0 auto;
        padding-top: 100px;

        
        &__content{
            margin-top: 30px;
        }

        &__title{
            text-align: start;
        }

        &__span{
            font-size: 34px;
            margin: 70px 0 0
        }

        &__emizavrik{
            right: 120px;
            top: -180px;
        }
    }    
}

@media screen and (max-width: 1440px){
    .form{
        padding-top: 80px;
        max-width: 1160px;

        &__title{
            font-size: 54px;
        }
        &__subtitle{
            font-size: 30px;
            text-align: center;
        }

        &__content{
            margin-top: 0;
        }

        &__span{
            font-size: 30px;
            margin: 50px 0 0;
        }

        &__square{
            margin-top: 0;
        }

        &__main_container{
            gap: 10px;
        }

        &__mainText{
            line-height: 180%;
        }

        &__emizavrik{
            width: 220px;
        }

    }    
}

@media screen and (max-height: 620px){
    .form{
        &__emizavrik{
            display: none;
        }
    }
}

@media screen and (max-width: 1160px){
    .form{
        max-width: 880px;

        &__title{
            text-align: center
        }

        &__content {
            flex-direction: column;
            flex-wrap: wrap;
            align-items:center;
            gap: 50px;
        }

        &__span{
            display: none;
        }

        &__main_container{
            gap: 30px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        &__mainText{
            text-align: center;
        }

        &__text{
            height: 210px;
            letter-spacing: 2.4px;
            text-align: start;
        }

        &__oblozhka{
            height: 180px;
            width: 290px;
            margin: 0 auto;
        }

        &__hide{
            display: flex;
            flex-direction: column;
            text-align: center;
        }

        &__emizavrik{
            top: 28%;
            right: 29%;
        }

        &__span2{
            margin: 0 0 20px 0;
            color: #12773D;
            font-size: 40px;
            font-style: normal;
            font-weight: 400;
            line-height: 160%; /* 64px */
            letter-spacing: 2px;
        }
    }    
}

@media screen and (max-width: 960px){
    .form{
        margin: 100px auto 100px auto;
        max-width: 648px;

        &__title{
            font-size: 50px;
        }

        &__emizavrik{
            top: 25%;
            right: 25%;
        }

        &__span2{
            font-size: 30px;
        }

        &__text_container{
            flex-direction: column;
            justify-content: center;
        }

        &__oblozhka_container{
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            align-content: center;
        }

        &__text{
            height: auto;
        }

    
      }
    }


@media screen and (max-width: 648px){
    .form{
        max-width: 586px;

        &__title{
            font-size: 40px;
            line-height: 120%; /* 48px */
            letter-spacing: 2px;
        }

        &__content{
            gap: 40px;
        }

        &__subtitle{
            font-size: 16px;
            line-height: 160%; /* 25.6px */
            letter-spacing: 0.8px;
            margin: 0 auto 10px auto;
        }

        &__mainText{
            font-size: 18px;
            text-align: justify;
            letter-spacing: 1px;
        }

        &__text{
            width: 318px;
            font-size: 16px;
            line-height: 160%; /* 25.6px */
            letter-spacing: 0.8px;
        }

        &__emizavrik{
            width: 221.705px;
            height: 197.105px;
            top: 23%;
            right: 29%;
        }

        &__square{
            height: auto;
        }

        &__span2{
            margin: 20px 0 20px 0;
            font-size: 24px;
            line-height: 160%; /* 38.4px */
            letter-spacing: 1.2px;

            &:nth-child(2){
                text-align: end;
                margin: 0;
            }
        }
    }
}

@media screen and (max-width: 586px){
    .form{
        max-width: 328px;
        padding-top: 0;

        &__title{
            font-size: 38px;
        }

        &__text{
            margin-top: 30px;
            text-align: center;
            max-width: 320px;
        }

        &__content{
            margin-top: 0;
        }

        &__square{
            width: 318px;
        }

        &__emizavrik{
            top: 23%;
            right: auto;
        }

        &__oblozhka{
            height: auto;
        }
    }    
}