.intro{
    max-width: 1640px;
    margin: 0 auto 200px auto;
    padding-top: 200px;
    gap: 60px;

    display: flex;

    &__container{
        display: flex;
        flex-direction: column;
    }

    &__image{
        height: 520px;
    }

    &__title{
        margin: 120px 0 20px 0;
        color: #74BF48;
        font-size: 66px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        letter-spacing: 3.6px;
    }

    &__text{
        margin: 0 0 100px 0;
        color: #12773D;
        font-size: 40px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;
        letter-spacing: 2px;
    }

    &__wrap{
        cursor: pointer;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        align-items: center;
        width: 230px;
        height: 56px;
        border-radius: 15px;
        border: 2px solid #12773D;
        background: #12773D;

        &:hover{
            border-radius: 15px;
            border: 2px solid #74BF48;
            background: #74BF48;
        }

        &:active{
            border: 2px solid #12773D;
        }
    }

    &__button{
        text-decoration: none;
        color: #FFF;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 160%;
        letter-spacing: 1.2px;
    }
}

@media screen and (max-width: 1640px){
    .intro{
        max-width: 1360px;
        margin: 0 auto 100px auto;
        padding-top: 150px;

        &__image{
            height: 450px;
        }

        &__title{
            margin: 60px 0 20px 0;
            font-size: 62px;
        }

        &__text{
            font-size: 38px;
        }
    }    
}

@media screen and (max-width: 1470px){
    .intro{
        max-width: 1160px;
        padding-top: 170px;

        &__image{
            margin-top: 50px;
            height: 400px;
        }

        &__title{
            font-size: 62px;
        }

        &__text{
            font-size: 32px;
        }
    }    
}

@media screen and (max-width: 960px){
    .intro{
        &__title{
            font-size: 52px;
        }
        &__text{
            margin: 0 0 70px 0;
        }
    }
}

@media screen and (max-height: 620px){
    .intro{
        padding-top: 100px;
    }
}

@media screen and (max-width: 1160px){
    .intro{
        max-width: 880px;
        margin: 0 auto 248px auto;

        &__image{
            height: 350px;
            padding-top: 100px;
        }

        &__title{
            margin: 20px 0 20px 0;
        }
    }    
}

@media screen and (max-width: 960px){
    .intro{
        max-width: 648px;
        position: relative;

        &__image{
            width: 300px;
            height: 250px;
            position: absolute;
            right: 10%;
            padding-top: 0;
            top: 60%;
        }
    } 
}

@media screen and (max-width: 648px){
    .intro{
        margin: 0 auto 90px auto;
        max-width: 586px;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: center;
        gap: 0;
        padding-top: 60px;

        &__image{
            position: static;
            margin-top: 30px;
        }

        &__title{
            font-size: 40px;
            letter-spacing: 2px;
            text-align: center;
        }

        &__text{
            margin: 0 0 30px 0;
            font-size: 24px;
            letter-spacing: 1.2px;
            text-align: center;
        }

        &__wrap{
            margin: 0 auto;
        }
    }    
}

