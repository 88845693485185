.policy{
    max-width: 1640px;
    margin:  0 auto;

    &__title{
        margin-top: 150px;
        color: #000;
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%; 
        letter-spacing: 2px;
    }

    &__text{
        color: #8F8F8F;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 38.4px */
        letter-spacing: 1.2px;
    }

    &__wrapper{
        display: flex;
        justify-content: center;
        margin-top: 100px;
    }


    &__button{
          padding: 9px 21px;
          border-radius: 15px;
          border: 2px solid #74BF48;
          background: #FFF;
          filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
          color: #12773D;
          font-weight: bold;
          font-size: 24px;
          line-height: 160%;
          letter-spacing: 1.2px;
    }
}

@media screen and (max-width: 1640px){
    .policy{
        max-width: 1360px;
    }    
}

@media screen and (max-width: 1440px){
    .policy{
        max-width: 1160px;
    }    
}

@media screen and (max-width: 1160px){
    .policy{
        max-width: 880px;

        &__title{
            margin-top: 150px;
        }
    }    
}

@media screen and (max-width: 960px){
    .policy{
        max-width: 648px;

        &__title{
            text-align: center;
            margin-top: 100px;
            font-size: 24px;
            line-height: 160%; 
            letter-spacing: 1.2px;
        }

        &__text{
            margin-top: 50px;
        }
    }
}

@media screen and (max-width: 648px){
    .policy{
        max-width: 586px;
    }    
}

@media screen and (max-width: 586px){
    .policy{
        max-width: 328px;

        &__text{
            font-size: 16px;
            letter-spacing: 0.8px;
        }
    }    
}

@media screen and (max-width: 385px){
    .policy{
        max-width: 310px;
    }    
}
