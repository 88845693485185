@import "../../vendor/variables";

.reasons{
    max-width: 1640px;
    margin: 100px auto 0 auto;
    padding-top: 100px;

    &__title{
        @include title();
    }

    &__subtitle{
        margin: 0;
        color: #8F8F8F;
        text-align: end;
        padding-right: 230px;
        font-size: 40px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; 
        letter-spacing: 2px;
    }

    &__wrapper{
        position: relative;
        z-index: 2;
    }

    &__content{
        margin-top: 50px;
        display: flex;
        justify-content: space-between;
    }

    &__item{
        display: flex;
        flex-direction: column;
        width: 400px;
        height: 461px;
        border-radius: 15px;
        border: 4px solid #74BF48;
        background-color: #FFF;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

        &:nth-child(2){
            border: 4px solid #12773D;
        }
    }

    &__desc {
        color: #000;
        text-align: center;
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%; 
        letter-spacing: 2px;
        margin-top: 20px;
    }

    &__desc2{
        text-align: center;
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%; 
        letter-spacing: 2px;
        color:#12773D;
        margin-top: 20px;
    }
  

    &__text{
        padding: 0 20px;
        margin: 0;
        color: #8F8F8F;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;
        letter-spacing: 1.2px;
    }

    &__final{
        margin: 120px auto 0 auto;
        color: #12773D;
        font-size: 40px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;
        letter-spacing: 2px;
        text-align: start;
    }

    &__final2{
        margin: 0 auto;
        color: #12773D;
        font-size: 40px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;
        letter-spacing: 2px;
        text-align: start;
    }

    &__ellipse1{
        position: absolute;
        width: 150px;
        top: 85%;
        left: 8%;
        z-index: -1;
    }

    &__ellipse2{
        position: absolute;
        width: 150px;
        top: 85%;
        left: 46%;
        z-index: -1;
    }

    &__ellipse3{
        position: absolute;
        width: 150px;
        right: 8%;
        top: 85%;
        z-index: -1;
    }
}

@media screen and (max-width: 1640px){
    .reasons{
        max-width: 1360px;
        padding-top: 75px;

        &__title{
            font-size: 60px;
        }

        &__subtitle{
            font-size: 34px;
        }

        &__content{
            margin-top: 20px;
        }

        &__item{
            width: 370px;
            height: auto;
        }

        &__desc{
            font-size: 38px;
            margin: 20px auto;
        }

        &__desc2{
            font-size: 38px;
            margin: 20px auto;
        }

        &__text{
            padding: 0 30px 20px;
            font-size: 22px;
        }

        &__ellipse2{
            left: 45%;
        }

        &__ellipse1,
        &__ellipse2, 
        &__ellipse3 {
            top: 82%;
        }

        &__final, &__final2{
            font-size: 34px;
        }
    }    
}

@media screen and (max-width: 1440px){
    .reasons{
        max-width: 1160px;

        &__item{
            width: 350px;
        }

        &__title{
            font-size: 54px;
        }

        &__final{
            margin: 65px auto 0 auto;
            font-size: 32px;
        }

        &__final2{
            margin-left: 10%;
            font-size: 32px;
        }

        &__text{
            letter-spacing: 1px;
     
        }

        &__desc, 
        &__desc2{
            font-size: 34px;
            margin: 10px auto;
        }
    }    
}

@media screen and (max-width: 1160px){
    .reasons{
        max-width: 880px;

        &__subtitle{
            padding-right: 130px;
        }

        &__content{
            flex-direction: column;
            gap: 30px;
        }

        &__item{
            width: 450px;
            margin-left: 10%;

            &:nth-child(2){
                margin-left: 30%;
            }
        }

        &__ellipse1{
            top: 8%;
            left: 50%;
        }

        &__ellipse2{
            left: 19%;
            top: 44%;
        }

        &__ellipse3{
            bottom: 8%;
            left: 50%;
        }

        &__final, &__final2{
            text-align: center;
        }
        
    }  
}

@media screen and (max-width: 960px){
    .reasons{
        max-width: 648px;

        &__title{
            font-size: 52px;
        }

        &__subtitle{
            padding-right: 0;
            font-size: 30px;
        }

        &__text{
            font-size: 18px;
        }

        &__desc, &__desc2{
            font-size: 28px;
        }

        &__ellipse1{
            top: 5%;
            left: 65%;
        }

        &__ellipse2{
            left: 22%;
            top: 44%;
        }

        &__ellipse3{
            top: 78%;
            left: 65%;
        }
    } 
}

@media screen and (max-width: 648px){
    .reasons{
        margin: 0 auto 100px auto;
        max-width: 586px;

        &__title{
            font-size: 40px;
            line-height: 120%; 
            letter-spacing: 2px;
        }

        &__subtitle{
            font-size: 24px;
            line-height: 160%; 
            letter-spacing: 1.2px;
        }

        &__item{
            width: 324px;
            height: 100%;
        }

        &__desc{
            margin: 20px auto 10px auto;
            font-size: 24px;
            line-height: 160%; 
            letter-spacing: 1.2px;
        }

        &__desc2{
            margin: 20px auto 0 auto;
            font-size: 24px;
            line-height: 160%; 
            letter-spacing: 1.2px;
        }

        &__text{
            padding: 12px;
            font-size: 16px;
            line-height: 160%; 
            letter-spacing: 0.8px;
        }

        &__ellipse1{
            top: 4%;
            left: 45%;
        }

        &__ellipse2{
            left: 18%;
            top: 38%;
        }

        &__ellipse3{
            top: 75%;
            left: 45%;
        }

        &__final{
            margin: 20px auto 0 auto;
            text-align: center;
            margin-left: 0;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: 160%;
            letter-spacing: 1.2px;

        }

        &__final2{
            text-align: center;
            margin-left: 0;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: 160%;
            letter-spacing: 1.2px;
        }

    }
}

@media screen and (max-width: 586px){
    .reasons{
        max-width: 328px;

        &__content{
            gap: 40px;
        }

        &__item{
            margin-left: 0;
            width: 324px;
            height: 100%;

            &:nth-child(2){
                margin-left: 0;
             }
        }

        &__ellipse1{
            display: none;
        }

        &__ellipse2{
            display: none;
        }

        &__ellipse3{
            display: none;
        }
    }
}
